import _get from 'lodash/get'

import { FC, useCallback, useEffect, useMemo } from 'react'

import { DIALOGS } from '@myopswat/common'
import { ButtonLoading, Grid, Typography, TypographyDivider } from '@opswat/react-ui'

import { useLazyOrganizationTreeQuery } from 'myopswat-web/src/api/license'
import { toggleDialogs } from 'myopswat-web/src/containers/LayoutContainer/layoutContainerSlice'
import { useAppDispatch } from 'myopswat-web/src/store'

import MyLicensesLoading from './components/MyLicensesLoading'
import SingleOrgLicenseView from './views/SingleOrgView'
import SubOrgViewProvider from './views/SubOrgView/context'

interface IProps {
  setCsvFilter: (data: any) => void
  activateSuccessful: boolean
}

const MyLicensesPage: FC<IProps> = ({ setCsvFilter, activateSuccessful }) => {
  const dispatch = useAppDispatch()

  const [getOrganizationTree, { data: organizationTreeData, isFetching: isFetchingOrganizationTree }] =
    useLazyOrganizationTreeQuery()

  const canViewSubOrg = useMemo(
    () => (_get(organizationTreeData, 'flatNodes') ?? []).length > 1,
    [organizationTreeData]
  )

  const handleOpenActivateLicenseDialog = useCallback(() => {
    dispatch(
      toggleDialogs({
        [DIALOGS.ACTIVE_LICENSES]: true
      })
    )
  }, [dispatch])

  useEffect(() => {
    getOrganizationTree()
  }, [])

  return (
    <Grid container spacing={2.5}>
      <Grid item xs={12}>
        <TypographyDivider
          isDivider={false}
          label="My Licenses"
          propsTypo={{
            component: 'label'
          }}
          actions={
            <ButtonLoading
              propsButton={{
                variant: 'contained',
                color: 'primary',
                disableElevation: true,
                onClick: handleOpenActivateLicenseDialog
              }}
              propsLoading={{ color: 'inherit' }}
            >
              Activate License
            </ButtonLoading>
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body2" component="label">
          This page summarizes all license purchases and evaluations of your organization.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {isFetchingOrganizationTree && <MyLicensesLoading />}
      </Grid>
      {!isFetchingOrganizationTree && organizationTreeData && (
        <Grid item container xs={12}>
          {canViewSubOrg ? (
            <SubOrgViewProvider
              permission={{}}
              setCsvFilter={setCsvFilter}
              organizationTreeData={organizationTreeData}
              activateSuccessful={activateSuccessful}
            />
          ) : (
            <SingleOrgLicenseView setCsvFilter={setCsvFilter} />
          )}
        </Grid>
      )}
    </Grid>
  )
}

export default MyLicensesPage
