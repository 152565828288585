import { gql } from 'graphql-request'

import { apiGraphql } from 'myopswat-web/src/api/base-query'

import {
  ILicenseCloudServicePinInput,
  ILicenseNoteAddInputV2,
  IOrganizationCloudServiceFilterInput,
  IProductLicenseFiltersInput
} from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    organizationCloudProducts: builder.query<any, IOrganizationCloudServiceFilterInput>({
      query: filters => ({
        document: `
          query ($filters: OrganizationCloudServiceFilterInput) {
            organizationsCloudServices (filters: $filters) {
              id
              name
              children
              cloudServices
              totalKeys
            }
          }
        `,
        variables: {
          filters
        }
      })
    }),
    organizationLicensedProducts: builder.query<any, IProductLicenseFiltersInput>({
      query: ({ filters, pinnedOnly }) => ({
        document: `
          query organizationProductsLicenseKeys($filters: ProductWithLicenseKeysFiltersInput, $pinnedOnly: Boolean) {
            organizationProductsLicenseKeys(filters: $filters, pinnedOnly: $pinnedOnly) {
              id
              name
              products {
                id
                name
                slug
                licenses
                children
              }
              children
              totalKeys
              hiddenKeys
            }
          }
        `,
        variables: {
          filters: filters ?? {},
          pinnedOnly
        }
      })
    }),
    organizationTree: builder.query<any, void>({
      query: () => ({
        document: `
          query {
            currentOrganizationTree {
              tree {
                id
                name
                children
              }
              flatNodes {
                id
                name
              }
            }
          }
        `
      })
    }),
    ogranizationLicenseKeyReport: builder.query<any, any>({
      query: ({ id }) => ({
        document: `
          query licenseKeyReport($id: UUID!) {
            licenseKeyReport(id: $id) {
              content
            }
          }
        `,
        variables: {
          id
        }
      })
    }),
    organizationLicenseHistory: builder.query<any, any>({
      query: ({ productId, orgId }) => ({
        document: `
          query productHistoryLicenseV2($productId: UUID!, $orgId: UUID!) {
            productHistoryLicenseV2(productId: $productId, orgId: $orgId) {
              products {
                id
                slug
                name
                tags
                children {
                  id
                  slug
                  licenses
                  name
                }
                licenses
                updatedAt
                createdAt
              }
              engineInfo {
                activationKey
                activatedDate
                productId
                platform
                type
                groupedEngines {
                  name
                  description
                  engines {
                    pattern
                    name
                  }
                }
                addedEngines {
                  pattern
                  name
                }
                isAllEngine
              }
            }
          }
        `,
        variables: {
          productId,
          orgId
        }
      })
    }),
    addOrganizationLicenseNote: builder.mutation<any, ILicenseNoteAddInputV2>({
      query: ({ id, content }) => ({
        document: gql`
          mutation licenseNoteUpdateV2($input: LicenseNoteInputV2!) {
            licenseNoteUpdateV2(input: $input) {
              errors
              success
            }
          }
        `,
        variables: {
          input: {
            id,
            content
          }
        }
      })
    }),
    pinOrganizationLicense: builder.mutation<any, string>({
      query: id => ({
        document: gql`
          mutation licenseKeyPin($input: LicenseKeyPinInput!) {
            licenseKeyPin(input: $input) {
              errors
              success
            }
          }
        `,
        variables: {
          input: {
            id
          }
        }
      })
    }),
    unpinOrganizationLicense: builder.mutation<any, string>({
      query: id => ({
        document: gql`
          mutation licenseKeyUnpin($input: LicenseKeyPinInput!) {
            licenseKeyUnpin(input: $input) {
              errors
              success
            }
          }
        `,
        variables: {
          input: {
            id
          }
        }
      })
    }),
    pinUnpinCloudProducts: builder.mutation<any, ILicenseCloudServicePinInput>({
      query: input => ({
        document: gql`
          mutation licenseCloudServicePin($input: LicenseCloudServicePinInput!) {
            licenseCloudServicePin(input: $input) {
              errors
              success
            }
          }
        `,
        variables: {
          input
        }
      })
    })
  })
})

export const {
  useLazyOrganizationTreeQuery,
  useOgranizationLicenseKeyReportQuery,
  useLazyOrganizationCloudProductsQuery,
  useLazyOrganizationLicensedProductsQuery,
  useLazyOrganizationLicenseHistoryQuery,
  useAddOrganizationLicenseNoteMutation,
  usePinOrganizationLicenseMutation,
  useUnpinOrganizationLicenseMutation,
  usePinUnpinCloudProductsMutation
} = api
