import _get from 'lodash/get'

import { FC, useCallback, useMemo } from 'react'

import { Grid, TableContainer, TreeCheckboxTable } from '@opswat/react-ui'

interface IProps {
  data: any[]
  isLoading: boolean
  columns: any[]
  childrenColumns: any[]
  childrenKeys: string[][]
  levelPadding: number
  defaultExpandedAll?: boolean
  noDataText?: React.ReactNode
  noHeader?: boolean
}

const NestedSubOrgTable: FC<IProps> = ({
  data,
  isLoading,
  columns,
  childrenColumns,
  childrenKeys,
  levelPadding,
  defaultExpandedAll,
  noDataText,
  noHeader
}) => {
  const handleRenderSubTrees = useCallback(() => {
    const children = _get(data, [0, 'children'], [])
    return children.map((item: any) => {
      return (
        <TreeCheckboxTable
          key={crypto.randomUUID()}
          noHeader
          isLoading={isLoading}
          columns={childrenColumns}
          data={[item]}
          childrenKeys={childrenKeys}
          levelPadding={40}
          initialPadding={16}
        />
      )
    })
  }, [data, isLoading, childrenColumns, childrenKeys, levelPadding])

  const TableMemo = useMemo(
    () => (
      <TreeCheckboxTable
        defaultExpanded
        defaultExpandedAll={defaultExpandedAll}
        isLoading={isLoading}
        columns={columns}
        data={data}
        childrenKeys={childrenKeys}
        levelPadding={24}
        noDataText={noDataText ?? 'There are no licenses to display.'}
        noHeader={noHeader}
      />
    ),
    [data, isLoading, columns, childrenKeys, levelPadding, defaultExpandedAll, noHeader]
  )

  return (
    <Grid item xs={12}>
      <TableContainer sx={{ overflowY: 'auto' }}>
        {TableMemo}
        {handleRenderSubTrees()}
      </TableContainer>
    </Grid>
  )
}

export default NestedSubOrgTable
