import _differenceInDays from 'date-fns/differenceInDays'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import _isInteger from 'lodash/isInteger'
import _startCase from 'lodash/startCase'

import { FC, useCallback } from 'react'

import { formatDatetime } from '@opswat/react-core'
import { DialogAdvanced, Typography } from '@opswat/react-ui'

import { homePageURL } from 'myopswat-web/src/routes'

import CloudServiceItem from '../components/CloudServiceItem'
import CloudServiceLabel from '../components/CloudServiceLabel'

interface IProps {
  item: any
  type: 'mdc' | 'ma'
  openDialog: boolean
  onClose: () => void
}

const DialogCloudLicenseDetail: FC<IProps> = ({ item, type, openDialog, onClose }) => {
  const handleShortenNumber = useCallback((value: number) => {
    if (!value) {
      return value
    }
    const number = Number(value)
    const SYMBOLS = ['', 'K', 'M', 'G', 'T', 'P', 'E']
    const tier = (Math.log10(Math.abs(number)) / 3) | 0
    if (tier == 0) {
      return number
    }

    const suffix = SYMBOLS[tier]
    const scale = Math.pow(10, tier * 3)
    const scaled = number / scale
    return scaled.toFixed(_isInteger(scaled) ? 0 : 1) + suffix
  }, [])

  const getLicenseData = useCallback((data: any, key: string) => {
    const value = _get(data, key)
    if ((value instanceof String && _isEmpty(value)) || value === null) return '--'
    return handleShortenNumber(value)
  }, [])

  const handleCheckStatus = useCallback((date: any) => {
    if (!date) return '--'

    if (date >= 90)
      return (
        <Typography component="span" fontSize="12px" lineHeight="20px" fontWeight={400} color="success.dark">
          Active
        </Typography>
      )
    if (date < 90 && date >= 30)
      return (
        <Typography component="span" fontSize="12px" lineHeight="20px" fontWeight={400} color="warning.dark">
          Expiring
        </Typography>
      )
    if (date < 30 && date >= 0)
      return (
        <Typography component="span" fontSize="12px" lineHeight="20px" fontWeight={400} color="error.dark">
          Expiring
        </Typography>
      )
    if (date < 0)
      return (
        <Typography component="span" fontSize="12px" lineHeight="20px" fontWeight={400} color="error.dark">
          Expired
        </Typography>
      )

    return '--'
  }, [])

  const handleRenderMDC = useCallback((data: any) => {
    return (
      <CloudServiceItem
        title={data.name ?? 'MetaDefender Cloud'}
        link={`${homePageURL}/metadefender-cloud`}
        leftInfo={
          <>
            <CloudServiceLabel
              title={'Prevention'}
              value={getLicenseData(data, 'mdcPreventionApi')}
              unit={{
                singular: 'request/day',
                plural: 'requests/day'
              }}
            />
            <CloudServiceLabel
              title={'Reputation'}
              value={getLicenseData(data, 'mdcReputationApi')}
              unit={{
                singular: 'request/day',
                plural: 'requests/day'
              }}
            />
            <CloudServiceLabel
              title={'Sandbox'}
              value={getLicenseData(data, 'sandboxApiLimit')}
              unit={{
                singular: 'request/day',
                plural: 'requests/day'
              }}
            />
            <CloudServiceLabel
              title={'Analysis'}
              value={getLicenseData(data, 'mdcAnalysisLimit')}
              unit={{
                singular: 'request/day',
                plural: 'requests/day'
              }}
            />
            <CloudServiceLabel
              title={'Feed'}
              value={getLicenseData(data, 'mdcFeedLimit')}
              unit={{
                singular: 'hash/day',
                plural: 'hashes/day'
              }}
            />
            <CloudServiceLabel title={'Throttle'} value={getLicenseData(data, 'mdCloudThrottle')} />
          </>
        }
        rightInfo={
          <>
            <Typography component="p" fontSize="12px" lineHeight="20px" fontWeight={500}>
              API Key
            </Typography>
            <Typography fontSize="12px" lineHeight="20px" fontWeight={400} sx={{ wordBreak: 'break-all' }}>
              {_get(data, 'mdcApiKey', '--')}
            </Typography>
            <CloudServiceLabel
              isNotNumeric
              title={'Type'}
              value=""
              unit={_get(data, 'mdCloudLicenseType') ? _startCase(_get(data, 'mdCloudLicenseType')) : '--'}
            />
            <CloudServiceLabel
              isNotNumeric
              title={'Expiration Date'}
              value=""
              unit={formatDatetime(_get(data, 'mdcExpiration'), 'MMM d, yyyy')}
            />
            <CloudServiceLabel
              isNotNumeric
              title={'Status'}
              value=""
              unit={handleCheckStatus(_get(data, 'daysToExpire'))}
            />
          </>
        }
      />
    )
  }, [])

  const handleRenderMA = useCallback((data: any) => {
    return (
      <CloudServiceItem
        title={data.name ?? 'MetaDefender IT Access'}
        link={`${homePageURL}/metadefender-it-access`}
        leftInfo={<CloudServiceLabel title={'Number of Devices'} value={_get(data, 'maLicMaxDevices') || '--'} />}
        rightInfo={
          <>
            <Typography component="p" fontSize="12px" lineHeight="20px" fontWeight={500}>
              API Key
            </Typography>
            <Typography fontSize="12px" lineHeight="20px" fontWeight={400} sx={{ wordBreak: 'break-all' }}>
              {_get(data, 'maApikey', '--')}
            </Typography>
            <CloudServiceLabel title="Type" value="" hidden />
            <CloudServiceLabel title={'Expiration Date'} value={_get(data, 'maExpirationDate') || '--'} />
            <CloudServiceLabel
              isNotNumeric
              title={'Status'}
              value={handleCheckStatus(_differenceInDays(new Date(_get(data, 'expiredDate') ?? ''), new Date()))}
            />
          </>
        }
      />
    )
  }, [])

  const handleRenderCloudLicense = useCallback(() => {
    switch (type) {
      case 'ma':
        return handleRenderMA(item)
      case 'mdc':
        return handleRenderMDC(item)
    }
  }, [type, item])

  return (
    <DialogAdvanced
      fullDialog
      open={openDialog}
      onClose={onClose}
      content={handleRenderCloudLicense()}
      disableBackdropClick={false}
      dialogProps={{
        sx: {
          '& .MuiPaper-root': {
            height: 'auto'
          },
          '& .MuiDialogContent-root': {
            padding: 0
          }
        }
      }}
    />
  )
}

export default DialogCloudLicenseDetail
