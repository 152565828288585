import _trim from 'lodash/trim'

import { gql } from 'graphql-request'

import { apiGraphql } from 'myopswat-web/src/api/base-query'
import {
  IDownloadedProductInput,
  IDownloadedProductsFilter,
  ILatestReleasesInput,
  ILicenseActivateInput,
  ILicenseFiltersInput,
  ILicenseKeyEngineInputItem,
  ILicenseNoteAddInput,
  IProductFilterInput
} from './types'

export const api = apiGraphql.injectEndpoints({
  endpoints: builder => ({
    products: builder.query<any, IProductFilterInput>({
      query: ({ q, tags, solutionIds, excludedTags }) => ({
        document: gql`
          query products($filters: ProductFiltersInput, $displayMode: String) {
            products(filters: $filters, displayMode: $displayMode) {
              id
              avatar
              thumbnail
              slug
              name
              type
              tags
              description
              solutions {
                name
              }
              interested
              updatedAt
              createdAt
            }
          }
        `,
        variables: {
          filters: {
            q,
            tags,
            solutionIds,
            excludedTags
          },
          displayMode: 'nested'
        }
      })
    }),
    basicProducts: builder.query<any, IProductFilterInput>({
      query: ({ q, tags }) => ({
        document: gql`
          query products($filters: ProductFiltersInput, $displayMode: String) {
            products(filters: $filters, displayMode: $displayMode) {
              id
              name
            }
          }
        `,
        variables: {
          filters: {
            q,
            tags
          },
          displayMode: 'nested'
        }
      })
    }),
    interestedProducts: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            interestedProducts {
              id
              avatar
              thumbnail
              slug
              name
              tags
              description
              solutions {
                name
              }
              interested
              updatedAt
              createdAt
            }
          }
        `
      })
    }),
    productInterestedAdd: builder.mutation<any, string>({
      query: id => ({
        document: gql`
          mutation productInterestedAdd($id: UUID!) {
            productInterestedAdd(id: $id) {
              errors
              success
            }
          }
        `,
        variables: {
          id
        }
      })
    }),
    productInterestedRemove: builder.mutation<any, string>({
      query: id => ({
        document: gql`
          mutation productInterestedRemove($id: UUID!) {
            productInterestedRemove(id: $id) {
              errors
              success
            }
          }
        `,
        variables: {
          id
        }
      })
    }),
    licensedProducts: builder.query<any, ILicenseFiltersInput>({
      query: ({ status, q, productId, productIds, licenseType }) => ({
        document: productId
          ? gql`
              query licensedProducts($filters: LicenseFiltersInput, $displayMode: String) {
                licensedProducts(filters: $filters, displayMode: $displayMode) {
                  children {
                    licenses
                  }
                  licenses
                }
              }
            `
          : gql`
              query licensedProducts($filters: LicenseFiltersInput, $displayMode: String) {
                licensedProducts(filters: $filters, displayMode: $displayMode) {
                  id
                  slug
                  name
                  tags
                  children {
                    id
                    slug
                    licenses
                    name
                  }
                  licenses
                  updatedAt
                  createdAt
                }
              }
            `,
        variables: {
          filters: {
            q,
            status,
            productIds: productId ? [productId] : productIds,
            licenseType
          },
          displayMode: 'nested'
        }
      })
    }),
    licenseKeyEngines: builder.query<any, ILicenseKeyEngineInputItem[]>({
      query: input_list => ({
        document: gql`
          query ($input: [LicenseKeyEngineInput]) {
            licenseKeyEngines(input: $input) {
              activationKey
              activatedDate
              productId
              platform
              type
              groupedEngines {
                name
                description
                engines {
                  pattern
                  name
                }
              }
              addedEngines {
                pattern
                name
              }
              isAllEngine
            }
          }
        `,
        variables: {
          input: input_list
        }
      })
    }),
    downloadedProducts: builder.query<any, IDownloadedProductsFilter>({
      query: ({ fromDatetime, toDatetime, q }) => ({
        document: gql`
          query downloadedProducts($filters: DownloadedProductFiltersInput) {
            downloadedProducts(filters: $filters) {
              id
              downloadedCount
              name
              slug
              tags
              latestDownloadedAt
              downloads {
                product {
                  name
                  tags
                }
                os
                version
                createdAt
                integrations
                otherIntegrations
              }
            }
          }
        `,
        variables: {
          filters: {
            q: q,
            fromDatetime: fromDatetime,
            toDatetime: toDatetime
          }
        }
      })
    }),
    activationServerProducts: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            activationServerProducts {
              id
              avatar
              slug
              name
              tags
              description
              solutions {
                name
              }
              interested
              updatedAt
              createdAt
            }
          }
        `
      })
    }),
    basicActivationServerProducts: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            productsForLicensedFilter {
              id
              name
            }
          }
        `
      })
    }),
    emailSubscriptionProducts: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            emailSubscriptionProducts {
              id
              name
            }
          }
        `
      })
    }),
    solutions: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            solutions {
              id
              name
              subscribed
            }
          }
        `
      })
    }),
    product: builder.query<any, string>({
      query: slug => ({
        document: gql`
          query PRODUCT_DETAIL {
            product (slug: "${slug}") {
              id
              parents {
                name
                id
                parents {
                  name
                  id
                }
              }
              avatar
              slug
              name
              description
              documentation
              latestVersion
              solutions {
                name
              }
              type
              tags
              downloadable
              asIds
              releaseDate
              updatedAt
              createdAt
              interested
              resources
              releases {
                id
                name
                version
                versionSuffix
                semVer
                assets
                releaseDate
                epochTime
              }
              releaseMetadata
              children {
                id
                slug
                name
                releaseMetadata
                downloadable
                latestVersion
                releases {
                  id
                  name
                  version
                  versionSuffix
                  semVer
                  assets
                  releaseDate
                  epochTime
                }
                resources
              }
              utilities {
                id
                avatar
                slug
                name
                description
                documentation
                latestVersion
                type
                tags
                releaseMetadata
                downloadable
                asIds
                releaseDate
                updatedAt
                createdAt
                solutions {
                  name
                }
                children {
                  id
                  name
                  releaseMetadata
                  downloadable
                  latestVersion
                  releases {
                    id
                    name
                    version
                    versionSuffix
                    semVer
                    assets
                    releaseDate
                    epochTime
                  }
                }
                parents {
                  name
                  slug
                  tags
                }
                releases {
                  id
                  name
                  version
                  versionSuffix
                  semVer
                  assets
                  releaseDate
                  epochTime
                }
                resources
              }
            }
          }
        `
      })
    }),
    downloadedProductAdd: builder.mutation<any, IDownloadedProductInput>({
      query: ({
        productId,
        os,
        md5,
        sha1,
        sha256,
        downloadLink,
        version,
        integrations,
        otherIntegrations,
        fromParentId
      }) => ({
        document: gql`
          mutation downloadedProductAdd($input: DownloadedProductInput!) {
            downloadedProductAdd(input: $input) {
              errors
              success
            }
          }
        `,
        variables: {
          input: {
            productId: productId,
            os: os,
            md5: md5,
            sha1: sha1,
            sha256: sha256,
            downloadLink: downloadLink,
            version: version,
            integrations: integrations,
            otherIntegrations: otherIntegrations,
            fromParentId: fromParentId
          }
        }
      })
    }),
    licenseNoteAdd: builder.mutation<any, ILicenseNoteAddInput>({
      query: ({ productId, licenseKey, content, organizationId }) => ({
        document: gql`
          mutation licenseNoteAdd($input: LicenseNoteInput!) {
            licenseNoteAdd(input: $input) {
              errors
              success
            }
          }
        `,
        variables: {
          input: {
            licenseKey: licenseKey,
            productId: productId,
            content: content,
            organizationId: organizationId
          }
        }
      })
    }),
    licenseActivate: builder.mutation<any, ILicenseActivateInput>({
      query: ({ productId, deployment, key, description, quantity }) => ({
        document: gql`
          mutation licenseActivate($input: LicenseInput!) {
            licenseActivate(input: $input) {
              success
              errors
              licenseText
            }
          }
        `,
        variables: {
          input: {
            productId: productId,
            deployment: _trim(deployment),
            key: _trim(key),
            description: description,
            quantity: quantity
          }
        }
      })
    }),
    productUtilities: builder.query<any, string>({
      query: id => ({
        document: gql`
          query productUtilities($id: UUID!) {
            productUtilities(id: $id) {
              slug
              id
              name
              slug
              downloadable
              description
            }
          }
        `,
        variables: {
          id
        }
      })
    }),
    deploymentIdsReport: builder.query<any, any>({
      query: ({ key, skus, productId }) => ({
        document: gql`
          query deploymentIdsReport($filters: LicenseReportFiltersInput!) {
            deploymentIdsReport(filters: $filters) {
              content
            }
          }
        `,
        variables: {
          filters: {
            key: key,
            skus: skus,
            productId: productId
          }
        }
      })
    }),
    icapIntegrations: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            icapIntegrations {
              name
              slug
              downloaded
            }
          }
        `
      })
    }),
    latestReleases: builder.query<any, ILatestReleasesInput>({
      query: filters => ({
        document: gql`
          query latestReleases($filters: ReleaseFiltersInput) {
            latestReleases(filters: $filters) {
              id
              name
              slug
              release {
                id
                name
                version
                semVer
                releaseDate
              }
            }
          }
        `,
        variables: {
          filters: filters
        }
      })
    }),
    publicProductReleases: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            publicProductReleases {
              name
              releases {
                md5
                name
                id
                releaseDate
                sha1
                sha256
                link
                version
              }
            }
          }
        `
      })
    }),
    productType: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            inUsedProductType
          }
        `
      })
    }),
    falseDetectionProducts: builder.query<any, void>({
      query: () => ({
        document: gql`
          query {
            falseDetectionProducts {
              id
              name
            }
          }
        `
      })
    })
  })
})

export const {
  useProductsQuery,
  useBasicProductsQuery,
  useLazyBasicProductsQuery,
  useInterestedProductsQuery,
  useProductInterestedAddMutation,
  useProductInterestedRemoveMutation,
  useLicensedProductsQuery,
  useLicenseKeyEnginesQuery,
  useDownloadedProductsQuery,
  useSolutionsQuery,
  useLazySolutionsQuery,
  useLazyProductQuery,
  useDownloadedProductAddMutation,
  useLazyEmailSubscriptionProductsQuery,
  useLicenseNoteAddMutation,
  useLicenseActivateMutation,
  useProductUtilitiesQuery,
  useDeploymentIdsReportQuery,
  useActivationServerProductsQuery,
  useLazyActivationServerProductsQuery,
  useLazyBasicActivationServerProductsQuery,
  useIcapIntegrationsQuery,
  useLazyIcapIntegrationsQuery,
  useLazyProductUtilitiesQuery,
  useLazyLicensedProductsQuery,
  useLazyLicenseKeyEnginesQuery,
  useLazyDownloadedProductsQuery,
  useLazyProductsQuery,
  useLazyLatestReleasesQuery,
  usePublicProductReleasesQuery,
  useFalseDetectionProductsQuery,
  useProductTypeQuery
} = api
