import { FC, useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

import _debounce from 'lodash/debounce'
import _map from 'lodash/map'

import { SolidDownIcon } from '@opswat/react-icon'
import { Button, CheckboxWithLabel, Divider, DropdownFilter, Grid, TextFieldSearch, Typography } from '@opswat/react-ui'

interface IProps {
  productOptions: any[]
  currentFilters: any
  setCurrentFilters: (data: any) => void
  handleSearchKeyword: (keyword: string) => void
}

const CloudServicesFilter: FC<IProps> = ({
  currentFilters,
  productOptions,
  setCurrentFilters,
  handleSearchKeyword
}) => {
  const [keyword, setKeyword] = useState<string>()

  const { handleSubmit, control, reset, setValue } = useForm<any>({
    defaultValues: { q: '', serviceNames: [] }
  })

  const handleSetCurrentFilters = useCallback((filters: any) => {
    Object.entries(filters).forEach(([key, value]: any) => {
      setValue(key, value)
    })
  }, [])

  const onSuccess = (data: any) => {
    setCurrentFilters({
      q: data.q,
      serviceNames: data.serviceNames
    })
  }

  const onFail = (e: any) => {
    console.error(e)
  }

  const handleOnChangeCheckbox = useCallback((onChange: any, e: any, value: any, item: any) => {
    onChange(e.target.checked ? [...value, item.id] : value.filter((i: any) => i !== item.id))
  }, [])

  const renderProductCheckbox = useCallback((item: any, idx: number) => {
    return (
      <Grid item xs={6} key={idx.toString()}>
        <Controller
          name="serviceNames"
          control={control}
          render={({ field: { value, onChange } }: any) => (
            <CheckboxWithLabel
              label={<Typography variant="subtitle2">{item.name}</Typography>}
              checkboxProps={{
                checked: value.includes(item.id),
                onChange: (e: any) => {
                  handleOnChangeCheckbox(onChange, e, value, item)
                }
              }}
              restProps={{
                sx: {
                  marginLeft: '-6px',
                  alignItems: 'flex-start'
                }
              }}
            />
          )}
        />
      </Grid>
    )
  }, [])

  useEffect(() => {
    if (keyword || keyword === '') {
      const handleSearch = _debounce(() => {
        handleSearchKeyword(keyword)
      }, 500)

      handleSearch()

      return () => {
        handleSearch.cancel()
      }
    }
  }, [keyword])

  useEffect(() => {
    handleSetCurrentFilters(currentFilters)
  }, [currentFilters])

  return (
    <Grid item container alignItems="center" spacing={2}>
      <Grid item xs={12} sm={10} md={6} lg={4}>
        <TextFieldSearch
          value={keyword}
          placeholder={'Search by service name, API key...'}
          onChange={(e: any) => {
            setKeyword(e.target.value)
          }}
          onClearText={() => setKeyword('')}
          sx={{
            minHeight: 'auto'
          }}
        />
      </Grid>
      <Grid item container xs="auto" alignItems="center">
        <Divider
          sx={{
            height: '20px',
            width: '1px',
            backgroundColor: '#E9ECF2',
            marginRight: '8px'
          }}
        />
        <DropdownFilter
          resetOnSubmit={false}
          propsPopper={{
            placement: 'bottom-start'
          }}
          propsPaper={{
            style: {
              width: '600px'
            }
          }}
          button={
            <Button color="inherit" variant="text" endIcon={<SolidDownIcon />}>
              Filter
            </Button>
          }
          content={
            <Grid container spacing={2}>
              {/* product filter */}
              <Grid item container xs={12} spacing={2}>
                <Grid item container xs={12} spacing={1} alignItems="center">
                  <Grid item xs={1.5}>
                    <Typography variant="subtitle1">Product</Typography>
                  </Grid>
                </Grid>
                {_map(productOptions, (item: any, idx: any) => renderProductCheckbox(item, idx))}
                <Grid item xs={12}>
                  <Divider sx={{ borderColor: '#D2D4D6' }} />
                </Grid>
              </Grid>
            </Grid>
          }
          onResetFilter={() => {
            reset()
          }}
          onResetChange={() => {
            handleSetCurrentFilters(currentFilters)
          }}
          onApply={() => {
            handleSubmit(onSuccess, onFail)()
          }}
        />
      </Grid>
    </Grid>
  )
}

export default CloudServicesFilter
