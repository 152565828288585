/** ----------------- PATHNAME -------------------- */
export const evaluatedLicensesPath = 'evaluated-licenses'
export const purchasedLicensesPath = 'purchased-licenses'
export const cloudBasedLicensesPath = 'cloud-based-products-licenses'

/** ----------------- ROUTE -------------------- */
export const licensedProductsPageURL = '/my-licenses'
// export const licensedProductsFlexPageURL = '/my-licenses/:tabPath'
// export const licensedProductsEvaluatedPageURL = `${licensedProductsPageURL}/${evaluatedLicensesPath}`
// export const licensedProductsPurchasedPageURL = `${licensedProductsPageURL}/${purchasedLicensesPath}`
// export const licensedProductsCloudBasedPageURL = `${licensedProductsPageURL}/${cloudBasedLicensesPath}`

export const iframeLicensedProductsPageURL = '/iframe-pages/licensed-products'
export const iframeLicensedProductsFlexPageURL = `${iframeLicensedProductsPageURL}/:tabPath`
export const iframeLicensedProductsEvaluatedPageURL = `${iframeLicensedProductsPageURL}/${evaluatedLicensesPath}`
export const iframeLicensedProductsPurchasedPageURL = `${iframeLicensedProductsPageURL}/${purchasedLicensesPath}`
export const iframeLicensedProductsCloudBasedPageURL = `${iframeLicensedProductsPageURL}/${cloudBasedLicensesPath}`
