import _get from 'lodash/get'
import _startCase from 'lodash/startCase'

import { CSSProperties, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { enqueueSnackbar } from 'notistack'

import { PinIcon } from '@opswat/react-icon'
import { ActionButton, Box, Grid, Typography, TypographyDivider, TypographyLineClamp } from '@opswat/react-ui'

import { DATE_FORMATS } from '@myopswat/common'
import { formatDatetime } from '@opswat/react-core'
import { IOrganizationCloudServiceFilterInput } from 'myopswat-web/src/api/license/types'

import { useLazyOrganizationCloudProductsQuery, usePinUnpinCloudProductsMutation } from 'myopswat-web/src/api/license'
import { homePageURL } from 'myopswat-web/src/routes'

import NestedSubOrgTable from '../../../components/NestedSubOrgTable'
import TooltipSubOrgLabel from '../../../components/TooltipSubOrgLabel'
import DialogCloudLicenseDetail from '../../../dialogs/DialogCloudLicenseDetail'
import { SubOrgViewContext } from '../interface'
import CloudServicesFilter from '../table/CloudServicesFilter'

const CloudSubOrgSection: FC<any> = () => {
  const { control } = useContext(SubOrgViewContext)

  const orgIds = useWatch({ control, name: 'organizationIds' })

  const [getCloudProducts, { isFetching }] = useLazyOrganizationCloudProductsQuery()
  const [pinUnpinCloudLicense] = usePinUnpinCloudProductsMutation()

  const [chosenItem, setChosenItem] = useState<any>()
  const [openDetailDialog, setOpenDetailDialog] = useState<boolean>(false)
  const [searchFilters, setSearchFilters] = useState<IOrganizationCloudServiceFilterInput>({
    q: '',
    serviceNames: [],
    orgIds: []
  })
  const [mappedProductsData, setMappedProductsData] = useState<any>({})
  const [mappedPinnedData, setMappedPinnedData] = useState<any>({})
  const [productOptions, setProductOptions] = useState<any[]>([])

  const handleCheckChosenOrgs = useCallback(
    (id: string) => {
      return orgIds.some((organization: any) => organization.value === id)
    },
    [orgIds]
  )

  const handleMapCloudLicense = useCallback((item: any, level: number): any => {
    const service = _get(item, 'service')
    const children = _get(item, 'children', [])

    switch (service) {
      case 'mdc':
        return {
          ...item,
          name: item.name,
          apiKey: item.apiKey,
          daysToExpire: item.days_to_expire,
          expiredDate: item.expired_date,
          status: item.status,
          type: item.mdCloudLicenseType,
          isParentService: children.length === 0 && level === 0
        }
      case 'ma':
        return {
          ...item,
          name: item.name,
          apiKey: item.apiKey,
          daysToExpire: item.maExpirationDate,
          expiredDate: item.expired_date,
          status: item.status,
          isParentService: children.length === 0 && level === 0
        }
      default:
        return item
    }
  }, [])

  const handleMapEmptyData = useCallback((item: any, index: number) => {
    const cloudServices = _get(item, 'cloudServices') ?? []
    const subOrgName = `SUB-ORGANIZATION ${index + 1}`
    if (cloudServices.length === 0) {
      return { name: item.name, subOrgName, apiKey: 'There are no licenses to display.' }
    }
    return {
      ...item,
      subOrgName,
      cloudServices: cloudServices.map((service: any) => handleMapCloudLicense(service, 1))
    }
  }, [])

  const handleMapPinnedEmptyData = useCallback((item: any, index: number) => {
    const cloudServices = _get(item, 'cloudServices') ?? []
    const subOrgName = `SUB-ORGANIZATION ${index + 1}`
    if (cloudServices.length === 0) {
      return null
    }
    return {
      ...item,
      subOrgName,
      cloudServices: cloudServices.map((service: any) => handleMapCloudLicense(service, 1))
    }
  }, [])

  const handleCheckEmptyData = useCallback((item: any) => {
    const cloudServices = _get(item, 'cloudServices') ?? []
    const children = _get(item, 'children') ?? []
    const checkEmptyChildren = children.every((child: any) => handleCheckEmptyData(child))
    return cloudServices.length === 0 && checkEmptyChildren
  }, [])

  const handleGetData = useCallback(
    async (filters: any) => {
      await getCloudProducts(filters)
        .unwrap()
        .then((response: any) => {
          const cloudServices = _get(response, 'cloudServices') ?? []
          const children = _get(response, 'children') ?? []

          if (productOptions.length === 0) {
            setProductOptions(
              cloudServices.map((item: any) => ({
                id: item.service,
                name: item.name
              }))
            )
          }

          setMappedProductsData({
            ...response,
            cloudServices: cloudServices.map((item: any) => handleMapCloudLicense(item, 0)),
            children: [...children]
              .filter((item: any) => orgIds.length === 0 || handleCheckChosenOrgs(item.id))
              .map((item: any, index: number) => handleMapEmptyData(item, index))
          })
        })
        .catch((error: any) => {
          console.error(error)
        })
    },
    [orgIds, productOptions]
  )

  const handleGetPinnedData = useCallback(async () => {
    await getCloudProducts({ q: '', serviceNames: [], orgIds: [], pinnedOnly: true })
      .unwrap()
      .then((response: any) => {
        const cloudServices = _get(response, 'cloudServices') ?? []
        const children = _get(response, 'children') ?? []
        setMappedPinnedData({
          ...response,
          cloudServices: cloudServices.map((item: any) => handleMapCloudLicense(item, 0)),
          children: [...children]
            .filter((item: any) => orgIds.length === 0 || handleCheckChosenOrgs(item.id))
            .map((item: any, index: number) => handleMapPinnedEmptyData(item, index))
            .filter(item => item)
        })
      })
      .catch((error: any) => {
        console.error(error)
      })
  }, [orgIds])

  const handleViewDetails = useCallback((item: any) => {
    setChosenItem(item)
    setOpenDetailDialog(true)
  }, [])

  const handlePinLicense = useCallback(async (data: any, isPinned?: boolean) => {
    await pinUnpinCloudLicense({ orgId: data.organization_id, service: data.service, pin: isPinned ?? true })
      .unwrap()
      .then((response: any) => {
        if (response.success) {
          enqueueSnackbar(`${isPinned ? 'Pinned' : 'Unpinned'} license successfully`, {
            variant: 'success'
          })
          handleGetPinnedData()
        }
      })
      .catch(() => {
        enqueueSnackbar(
          `${isPinned ? 'Pinning' : 'Unpinning'} license has failed. Please give the system a moment then try again.`,
          {
            variant: 'error'
          }
        )
      })
  }, [])

  const renderCell = useCallback((data: any, key: string, sx?: CSSProperties, hideEmpty?: boolean) => {
    const value = data[key]
    const emptyValue = hideEmpty ? '' : '--'
    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
        tooltipValue={value}
        sx={sx}
      >
        {value || emptyValue}
      </TypographyLineClamp>
    )
  }, [])

  const renderDateCell = useCallback((data: any, key: string) => {
    const value = formatDatetime(data[key], DATE_FORMATS.DATE)
    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipValue={value}
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
      >
        {value}
      </TypographyLineClamp>
    )
  }, [])

  const renderOrgNameCell = useCallback((data: any, sx?: CSSProperties) => {
    const orgName = _get(data, 'name')
    const subOrgName = _get(data, 'subOrgName')

    return <TooltipSubOrgLabel mainLabel={subOrgName} subLabel={orgName} sx={sx} />
  }, [])

  const renderTypeCell = useCallback((data: any) => {
    const value = _startCase(_get(data, 'type')) || '--'
    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipValue={value}
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
      >
        {value}
      </TypographyLineClamp>
    )
  }, [])

  const renderStatusCell = useCallback((data: any) => {
    const value = _get(data, 'status') ?? '--'
    const daysToExpire = _get(data, 'days_to_expire', 0)

    let color = '#008A00'
    if (value === 'expired') {
      color = '#D00300'
    } else if (value === 'expiring') {
      if (daysToExpire < 90 && daysToExpire >= 30) {
        color = '#FDBD0D'
      } else {
        color = '#D00300'
      }
    }

    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipValue={value}
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
        textTransform="capitalize"
        style={{ color }}
      >
        {value}
      </TypographyLineClamp>
    )
  }, [])

  const renderActionCell = useCallback((data: any) => {
    const childItems = [
      {
        label: 'Pin',
        onClick: () => {
          handlePinLicense(data, true)
        }
      },
      {
        label: 'View Details',
        onClick: () => {
          handleViewDetails(data)
        }
      }
    ]

    return (
      <ActionButton
        items={childItems}
        paperStyles={{
          minWidth: '150px'
        }}
        buttonStyles={{
          padding: '0px'
        }}
      />
    )
  }, [])

  const renderPinActionCell = useCallback((data: any) => {
    const childItems = [
      {
        label: 'Unpin',
        onClick: () => {
          handlePinLicense(data, false)
        }
      },
      {
        label: 'View Details',
        onClick: () => {
          handleViewDetails(data)
        }
      }
    ]

    return (
      <ActionButton
        items={childItems}
        paperStyles={{
          minWidth: '150px'
        }}
        buttonStyles={{
          padding: '0px'
        }}
      />
    )
  }, [])

  const cloudColumns = useMemo(() => {
    return [
      {
        header: 'Service Name',
        style: { minWidth: '20vw', width: '20vw' },
        body: (data: any) =>
          renderOrgNameCell(data, {
            color: _get(data, 'isParentService') ? '#1d6dfc' : undefined
          }),
        highlightLevels: [0],
        linkLevels: (data: any) => {
          return _get(data, 'isParentService')
            ? ['', `${homePageURL}/${_get(data, 'service') === 'ma' ? 'metadefender-it-access' : 'metadefender-cloud'}`]
            : []
        }
      },
      {
        header: 'API Key',
        style: { minWidth: '18vw', width: '18vw' },
        body: (data: any) => renderCell(data, 'apiKey', {}, true)
      },
      {
        header: 'Status',
        style: {
          minWidth: '8vw',
          width: '8vw',
          textAlign: 'center',
          sx: {
            justifyContent: 'center'
          }
        },
        body: renderStatusCell,
        hiddenLevels: [0]
      },
      {
        header: 'Expiration Date',
        style: { minWidth: '10vw', width: '10vw' },
        body: (data: any) => renderDateCell(data, 'expired_date'),
        hiddenLevels: [0]
      },
      {
        header: 'Type',
        style: { minWidth: '10vw', width: '10vw' },
        body: renderTypeCell,
        hiddenLevels: [0]
      },
      {
        header: '',
        style: { minWidth: 40, textAlign: 'right' },
        body: renderActionCell,
        hiddenLevels: [0]
      }
    ]
  }, [])

  const pinnedColumns = useMemo(() => {
    const columns = [...cloudColumns]
    columns.pop()
    return [
      ...columns,
      {
        header: '',
        style: { minWidth: 40, textAlign: 'right' },
        body: renderPinActionCell,
        hiddenLevels: [0]
      }
    ]
  }, [cloudColumns])

  const TableMemo = useMemo(
    () => (
      <NestedSubOrgTable
        data={handleCheckEmptyData(mappedProductsData) ? [] : [mappedProductsData]}
        isLoading={isFetching}
        columns={cloudColumns}
        childrenColumns={cloudColumns}
        childrenKeys={[['cloudServices'], ['licenses', 'children'], ['licenses'], ['']]}
        levelPadding={24}
      />
    ),
    [mappedProductsData, isFetching, cloudColumns]
  )

  const PinnedTableMemo = useMemo(
    () => (
      <NestedSubOrgTable
        noHeader
        isLoading={isFetching}
        columns={pinnedColumns}
        childrenColumns={pinnedColumns}
        data={handleCheckEmptyData(mappedPinnedData) ? [] : [mappedPinnedData]}
        childrenKeys={[['cloudServices'], ['licenses', 'children'], ['licenses'], ['']]}
        levelPadding={24}
        noDataText="There are no licenses to display."
      />
    ),
    [pinnedColumns, mappedPinnedData, isFetching]
  )

  useEffect(() => {
    handleGetData({ ...searchFilters, orgIds: orgIds.map((item: any) => item.value) })
  }, [searchFilters, orgIds])

  useEffect(() => {
    handleGetPinnedData()
  }, [])

  return (
    <Grid item container xs={12}>
      <Grid item container xs={12} sx={{ padding: '15px', border: '1px solid #BCBFC3', gap: 4 }}>
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <TypographyDivider
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, height: '20px' }}>
                <PinIcon size={16} />
                <Typography variant="body1" marginTop="2px">
                  Pinned ({_get(mappedPinnedData, 'totalKeys', 0)})
                </Typography>
              </Box>
            }
            propsTypo={{
              component: 'label',
              variant: 'body1'
            }}
          />
          {PinnedTableMemo}
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2
          }}
        >
          <TypographyDivider
            label="Cloud Services"
            propsTypo={{
              component: 'label',
              variant: 'body1'
            }}
          />
          <CloudServicesFilter
            productOptions={productOptions}
            currentFilters={searchFilters}
            setCurrentFilters={setSearchFilters}
            handleSearchKeyword={(keyword: string) => {
              setSearchFilters((prev: any) => ({ ...prev, q: keyword.trim() }))
            }}
          />
          {TableMemo}
        </Grid>
      </Grid>
      {chosenItem && (
        <DialogCloudLicenseDetail
          item={chosenItem}
          type={_get(chosenItem, 'service')}
          openDialog={openDetailDialog}
          onClose={() => setOpenDetailDialog(false)}
        />
      )}
    </Grid>
  )
}

export default CloudSubOrgSection
