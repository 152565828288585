import React from 'react'
import { Control } from 'react-hook-form'

export type SubOrgViewContextProps = {
  control: Control<any>
  organizationList: any[]
  activateSuccessful: boolean
  setCsvFilter: (data: any) => void
}

export const SubOrgViewContext = React.createContext<SubOrgViewContextProps>({
  control: {} as Control<any>,
  organizationList: [],
  activateSuccessful: false,
  setCsvFilter: () => {
    return
  }
})
