import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import _debounce from 'lodash/debounce'
import _get from 'lodash/get'
import _map from 'lodash/map'

import { SolidDownIcon } from '@opswat/react-icon'
import { Button, CheckboxWithLabel, Divider, DropdownFilter, Grid, TextFieldSearch, Typography } from '@opswat/react-ui'

import { useLazyBasicActivationServerProductsQuery } from 'myopswat-web/src/api/product'
import { ALL_VALUE_OPTION, LICENSE_STATUS_OPTIONS, LICENSE_TYPE_OPTIONS } from '../../../constants'

interface IProps {
  currentFilters: any
  setCurrentFilters: (data: any) => void
  handleSearchKeyword: (keyword: string) => void
}

const LicensedProductsFilter: FC<IProps> = ({ currentFilters, setCurrentFilters, handleSearchKeyword }) => {
  const { t: translate } = useTranslation()

  const [keyword, setKeyword] = useState<string>()
  const [productKeyword, setProductKeyword] = useState<string>()

  const [getProductOptions, { data: productOptions }] = useLazyBasicActivationServerProductsQuery()

  const { handleSubmit, control, reset, setValue } = useForm<any>({
    defaultValues: { q: '', productIds: [], licenseStatuses: [], licenseType: [] }
  })

  const searchParams = useMemo(() => new URLSearchParams(window.location.search), [window.location.search])

  const filteredProducts = useMemo(() => {
    return productOptions
      ?.filter((product: any) => product.name.toLowerCase().includes(productKeyword?.toLowerCase() ?? ''))
      .sort((a: any, b: any) => a.name.localeCompare(b.name))
  }, [productOptions, productKeyword])

  const handleSetCurrentFilters = useCallback((filters: any) => {
    Object.entries(filters).forEach(([key, value]: any) => {
      if (key === 'licenseStatuses' && value.includes(ALL_VALUE_OPTION)) {
        setValue(key, [])
      } else if (key === 'licenseType') {
        setValue(key, value ? [value] : [])
      } else {
        setValue(key, value)
      }
    })
  }, [])

  const handleMapCheckbox = useCallback((checked: boolean, currentValue: any[], item: any) => {
    return checked ? [...currentValue, item.value] : currentValue.filter((i: any) => i !== item.value)
  }, [])

  const handleMapStatus = useCallback((status: string[]) => {
    const length = status.length
    if (length === LICENSE_STATUS_OPTIONS.length || length === 0) {
      return [ALL_VALUE_OPTION]
    }
    return status
  }, [])

  const handleGetLicenseType = useCallback((licenseType: string[]) => {
    const length = licenseType.length
    if (length === LICENSE_TYPE_OPTIONS.length || length === 0) {
      return ''
    }
    return licenseType.at(0)
  }, [])

  const onSuccess = (data: any) => {
    setCurrentFilters({
      licenseType: handleGetLicenseType(data.licenseType),
      licenseStatuses: handleMapStatus(data.licenseStatuses),
      productIds: data.productIds,
      q: data.q
    })
  }

  const onFail = (e: any) => {
    console.error(e)
  }

  const handleOnChangeCheckbox = useCallback((onChange: any, e: any, value: any, item: any) => {
    onChange(e.target.checked ? [...value, item.id] : value.filter((i: any) => i !== item.id))
  }, [])

  const renderProductCheckbox = useCallback((item: any, idx: number) => {
    return (
      <Grid item xs={6} key={idx.toString()}>
        <Controller
          name="productIds"
          control={control}
          render={({ field: { value, onChange } }: any) => (
            <CheckboxWithLabel
              label={<Typography variant="subtitle2">{item.name}</Typography>}
              checkboxProps={{
                checked: value.includes(item.id),
                onChange: (e: any) => {
                  handleOnChangeCheckbox(onChange, e, value, item)
                }
              }}
              restProps={{
                sx: {
                  marginLeft: '-6px',
                  alignItems: 'flex-start'
                }
              }}
            />
          )}
        />
      </Grid>
    )
  }, [])

  const renderStatusCheckbox = useCallback(
    (item: any, idx: number) => {
      return (
        <Grid item xs={4} key={idx.toString()}>
          <Controller
            name="licenseStatuses"
            control={control}
            render={({ field: { value, onChange } }: any) => (
              <CheckboxWithLabel
                label={<Typography variant="subtitle2">{item.label}</Typography>}
                checkboxProps={{
                  checked: value.includes(item.value),
                  onChange: (e: any) => {
                    onChange(handleMapCheckbox(e.target.checked, value, item))
                  }
                }}
                restProps={{
                  sx: {
                    marginLeft: '-6px'
                  }
                }}
              />
            )}
          />
        </Grid>
      )
    },
    [handleMapCheckbox]
  )

  const renderTypeCheckbox = useCallback(
    (item: any, idx: number) => {
      return (
        <Grid item xs={4} key={idx.toString()}>
          <Controller
            name="licenseType"
            control={control}
            render={({ field: { value, onChange } }: any) => (
              <CheckboxWithLabel
                label={<Typography variant="subtitle2">{item.label}</Typography>}
                checkboxProps={{
                  checked: value.includes(item.value),
                  onChange: (e: any) => {
                    onChange(handleMapCheckbox(e.target.checked, value, item))
                  }
                }}
                restProps={{
                  sx: {
                    marginLeft: '-6px'
                  }
                }}
              />
            )}
          />
        </Grid>
      )
    },
    [handleMapCheckbox]
  )

  useEffect(() => {
    getProductOptions()
  }, [])

  useEffect(() => {
    const productQuery = searchParams.get('q')
    productQuery && setKeyword(productQuery)
  }, [searchParams])

  useEffect(() => {
    if (keyword || keyword === '') {
      const handleSearch = _debounce(() => {
        handleSearchKeyword(keyword)
      }, 500)

      handleSearch()

      return () => {
        handleSearch.cancel()
      }
    }
  }, [keyword])

  useEffect(() => {
    handleSetCurrentFilters(currentFilters)
  }, [currentFilters])

  return (
    <Grid item container alignItems="center" spacing={2}>
      <Grid item xs={12} sm={10} md={6} lg={4}>
        <TextFieldSearch
          value={keyword}
          placeholder={translate('filterProductName') ?? ''}
          onChange={(e: any) => {
            setKeyword(e.target.value)
          }}
          onClearText={() => setKeyword('')}
          sx={{
            minHeight: 'auto'
          }}
        />
      </Grid>
      <Grid item container xs="auto" alignItems="center">
        <Divider
          sx={{
            height: '20px',
            width: '1px',
            backgroundColor: '#E9ECF2',
            marginRight: '8px'
          }}
        />
        <DropdownFilter
          resetOnSubmit={false}
          propsPopper={{
            placement: 'bottom-start'
          }}
          propsPaper={{
            style: {
              width: '600px'
            }
          }}
          button={
            <Button color="inherit" variant="text" endIcon={<SolidDownIcon />}>
              Filter
            </Button>
          }
          content={
            <Grid container spacing={2}>
              {/* product filter */}
              <Grid item container xs={12} spacing={2}>
                <Grid item container xs={12} spacing={1} alignItems="center">
                  <Grid item xs={1.5}>
                    <Typography variant="subtitle1">Product</Typography>
                  </Grid>
                  <Grid item xs={6.5} gap={2} sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextFieldSearch
                      value={productKeyword}
                      placeholder={'Search products...'}
                      onChange={(e: any) => {
                        if (_get(e, 'target')) {
                          setProductKeyword(e.target.value)
                        }
                      }}
                      onClearText={() => setProductKeyword('')}
                      sx={{
                        minHeight: 'auto'
                      }}
                    />
                    <Divider
                      sx={{
                        height: '20px',
                        width: '1px',
                        backgroundColor: '#D2D4D6'
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      variant="text"
                      color="inherit"
                      onClick={() => {
                        setValue('productIds', [])
                      }}
                    >
                      Clear Selected Products
                    </Button>
                  </Grid>
                </Grid>
                {_map(filteredProducts, (item: any, idx: any) => renderProductCheckbox(item, idx))}
                <Grid item xs={12}>
                  <Divider sx={{ borderColor: '#D2D4D6' }} />
                </Grid>
              </Grid>
              {/* status filter */}
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Status</Typography>
                </Grid>
                {_map(LICENSE_STATUS_OPTIONS, (item: any, idx: any) => renderStatusCheckbox(item, idx))}
                <Grid item xs={12}>
                  <Divider sx={{ borderColor: '#D2D4D6' }} />
                </Grid>
              </Grid>
              {/* type filter */}
              <Grid item container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1">Type</Typography>
                </Grid>
                {_map(LICENSE_TYPE_OPTIONS, (item: any, idx: any) => renderTypeCheckbox(item, idx))}
                <Grid item xs={12}>
                  <Divider sx={{ borderColor: '#D2D4D6' }} />
                </Grid>
              </Grid>
            </Grid>
          }
          onResetFilter={() => {
            reset()
          }}
          onResetChange={() => {
            handleSetCurrentFilters(currentFilters)
          }}
          onApply={() => {
            handleSubmit(onSuccess, onFail)()
          }}
        />
      </Grid>
    </Grid>
  )
}

export default LicensedProductsFilter
