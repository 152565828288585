import { FC } from 'react'

import MyLicensesPage from './MyLicensesPage'

interface IProps {
  setCsvFilter: (data: any) => void
  activateSuccessful: boolean
}

const LicensedProductsPage: FC<IProps> = ({ setCsvFilter, activateSuccessful }) => {
  return <MyLicensesPage setCsvFilter={setCsvFilter} activateSuccessful={activateSuccessful} />
}

export default LicensedProductsPage
