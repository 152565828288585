import _get from 'lodash/get'

import { enqueueSnackbar } from 'notistack'
import { CSSProperties, FC, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { DATE_FORMATS } from '@myopswat/common'
import { formatDatetime } from '@opswat/react-core'
import { CloseIcon, PinIcon } from '@opswat/react-icon'
import {
  ActionButton,
  Box,
  DialogAdvanced,
  Grid,
  Typography,
  TypographyDivider,
  TypographyLineClamp
} from '@opswat/react-ui'

import {
  useAddOrganizationLicenseNoteMutation,
  useLazyOrganizationLicensedProductsQuery,
  usePinOrganizationLicenseMutation,
  useUnpinOrganizationLicenseMutation
} from 'myopswat-web/src/api/license'
import { IProductWithLicenseKeysFiltersInput } from 'myopswat-web/src/api/license/types'
import {
  useDownloadedProductAddMutation,
  useLazyBasicActivationServerProductsQuery,
  useLazyIcapIntegrationsQuery,
  useLazyProductQuery
} from 'myopswat-web/src/api/product'
import { IDownloadedProductInput, ILicenseNoteAddInputV2 } from 'myopswat-web/src/api/product/types'
import DialogDownloadVersion from 'myopswat-web/src/components/Dialog/DialogDownloadVersion'
import { PRODUCT_IDS } from 'myopswat-web/src/constants/product-ids'
import BoxCommonDialogNote from 'myopswat-web/src/pages/LicensedProductsPage/CommonProduct/BoxCommonDialogNote'
import { homePageURL } from 'myopswat-web/src/routes'

import NestedSubOrgTable from '../../../components/NestedSubOrgTable'
import TooltipSubOrgLabel from '../../../components/TooltipSubOrgLabel'
import { ALL_VALUE_OPTION } from '../../../constants'
import DialogLicenseHistory from '../../../dialogs/DialogLicenseHistory'
import LicensedProductsSectionFilter from '../table/LicensedProductsFilter'

import { SubOrgViewContext } from '../interface'

const ProductsSubOrgSection: FC<any> = () => {
  const { t } = useTranslation()
  const { setCsvFilter, control, activateSuccessful } = useContext(SubOrgViewContext)

  const organizationIds = useWatch({ control, name: 'organizationIds' })

  const [getLicensedProducts, { isFetching }] = useLazyOrganizationLicensedProductsQuery()
  const [getIcapIntegrations, { data: icapIntegrationsData }] = useLazyIcapIntegrationsQuery()
  const [getProduct, { data: productDetailData, isFetching: isFetchingProductDetail }] = useLazyProductQuery()
  const [getProductOptions, { data: productOptions }] = useLazyBasicActivationServerProductsQuery()

  const [downloadedProductAdd] = useDownloadedProductAddMutation()
  const [licenseNoteAdd, licenseNoteAddResponse] = useAddOrganizationLicenseNoteMutation()
  const [pinLicense] = usePinOrganizationLicenseMutation()
  const [unpinLicense] = useUnpinOrganizationLicenseMutation()

  const [chosenItem, setChosenItem] = useState<any>()
  const [openProductDialog, setOpenProductDialog] = useState<boolean>(false)
  const [openNoteDialog, setOpenNoteDialog] = useState<boolean>(false)
  const [openHistoryDialog, setOpenHistoryDialog] = useState<boolean>(false)
  const [searchFilters, setSearchFilters] = useState<IProductWithLicenseKeysFiltersInput>({
    q: '',
    productIds: [],
    licenseType: '',
    licenseStatuses: [ALL_VALUE_OPTION],
    organizationIds: []
  })
  const [mappedProductsData, setMappedProductsData] = useState<any>({})
  const [mappedPinnedData, setMappedPinnedData] = useState<any>({})
  const [hiddenProductsData, setHiddenProductsData] = useState<any>([])
  const [firstLoad, setFirstLoad] = useState<boolean>(true)

  const handleEditNote = useCallback((item: any) => {
    setChosenItem(item)
    setOpenNoteDialog(true)
  }, [])

  const handleViewHistory = useCallback((item: any) => {
    setChosenItem(item)
    setOpenHistoryDialog(true)
  }, [])

  const handleDownloadProduct = useCallback((item: any) => {
    if (_get(item, 'slug', '')) {
      getProduct(_get(item, 'slug', ''))
      if (_get(item, 'id') === PRODUCT_IDS.MD_ICAP) {
        getIcapIntegrations()
      }
    }
    setChosenItem(item)
    setOpenProductDialog(true)
  }, [])

  const handleDownloadedProductAdd = useCallback(async (data: IDownloadedProductInput, isICAP?: boolean) => {
    try {
      await downloadedProductAdd(data).unwrap()
      if (isICAP) {
        getIcapIntegrations()
      }
    } catch (error) {
      console.error(error)
    }
  }, [])

  const handleLicenseNoteAdd = useCallback(
    async (data: ILicenseNoteAddInputV2) => {
      await licenseNoteAdd({ ...data, id: _get(chosenItem, 'id', '') })
        .unwrap()
        .then((response: any) => {
          if (response.success) {
            setOpenNoteDialog(false)
            handleGetData({ ...searchFilters, organizationIds: organizationIds.map((item: any) => item.value) })
            enqueueSnackbar(t('updateLicensesNoteSuccess'), {
              variant: 'success'
            })
          }
        })
        .catch(() => {
          enqueueSnackbar(t('updateLicensesNoteFail'), {
            variant: 'error'
          })
        })
    },
    [chosenItem, searchFilters, organizationIds]
  )

  const handleDownloadCSV = useCallback((data: any) => {
    const params = {
      id: data.id,
      key: data.activation_key
    }

    setCsvFilter?.(params)
  }, [])

  const handlePinLicense = useCallback(async (id: any) => {
    await pinLicense(id)
      .unwrap()
      .then((response: any) => {
        if (response.success) {
          enqueueSnackbar('Pinned license successfully', {
            variant: 'success'
          })
          handleGetPinnedData()
        }
      })
      .catch(() => {
        enqueueSnackbar('Pinning license has failed. Please give the system a moment then try again.', {
          variant: 'error'
        })
      })
  }, [])

  const handleUnpinLicense = useCallback(async (id: any) => {
    await unpinLicense(id)
      .unwrap()
      .then((response: any) => {
        if (response.success) {
          enqueueSnackbar('Unpinned license successfully', {
            variant: 'success'
          })
          handleGetPinnedData()
        }
      })
      .catch(() => {
        enqueueSnackbar('Unpinning license has failed. Please give the system a moment then try again.', {
          variant: 'error'
        })
      })
  }, [])

  const handleMapEmptyData = useCallback((item: any, index: number) => {
    const products = _get(item, 'products') ?? []
    const subOrgName = `SUB-ORGANIZATION ${index + 1}`
    if (products.length === 0) {
      return { name: item.name, subOrgName, activation_key: 'There are no licenses to display.' }
    }
    return {
      ...item,
      subOrgName
    }
  }, [])

  const handleMapPinnedEmptyData = useCallback((item: any, index: number) => {
    const products = _get(item, 'products') ?? []
    const subOrgName = `SUB-ORGANIZATION ${index + 1}`
    if (products.length === 0) {
      return null
    }
    return {
      ...item,
      subOrgName
    }
  }, [])

  const handleCheckEmptyData = useCallback((item: any) => {
    const products = _get(item, 'products') ?? []
    const children = _get(item, 'children') ?? []
    const checkEmptyChildren = children.every((child: any) => handleCheckEmptyData(child))
    return products.length === 0 && checkEmptyChildren
  }, [])

  const handleCheckChosenOrgs = useCallback(
    (id: string) => {
      return organizationIds.some((organization: any) => organization.value === id)
    },
    [organizationIds]
  )

  const handleMapLicense = useCallback((item: any): any => {
    // for first level parent org only
    const licenses: any[] = _get(item, 'licenses') ?? []
    return {
      ...item,
      licenses: [...licenses].map((license: any) => ({ ...license, is_parent_org: true }))
    }
  }, [])

  const handleGetData = useCallback(
    async (filters: any) => {
      await getLicensedProducts({ filters: { ...filters, firstLoad } })
        .unwrap()
        .then((response: any) => {
          setFirstLoad(false)
          setMappedProductsData({
            ...response,
            products: response.products.map((item: any) => handleMapLicense(item)),
            children: [...response.children]
              .filter((item: any) => organizationIds.length === 0 || handleCheckChosenOrgs(item.id))
              .map((item: any, index: number) => handleMapEmptyData(item, index))
          })
          setHiddenProductsData(_get(response, 'hiddenKeys', []))
        })
        .catch((error: any) => {
          console.error(error)
        })
    },
    [firstLoad, organizationIds, handleCheckChosenOrgs]
  )

  const handleGetPinnedData = useCallback(async () => {
    await getLicensedProducts({ pinnedOnly: true })
      .unwrap()
      .then((response: any) => {
        setMappedPinnedData({
          ...response,
          products: response.products.map((item: any) => handleMapLicense(item)),
          children: [...response.children]
            .filter((item: any) => organizationIds.length === 0 || handleCheckChosenOrgs(item.id))
            .map((item: any, index: number) => handleMapPinnedEmptyData(item, index))
            .filter(item => item)
        })
      })
      .catch((error: any) => {
        console.error(error)
      })
  }, [organizationIds, handleCheckChosenOrgs])

  const renderCell = useCallback((data: any, key: string, sx?: CSSProperties) => {
    const value = data[key]
    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
        tooltipValue={value}
        sx={sx}
      >
        {value || ''}
      </TypographyLineClamp>
    )
  }, [])

  const renderDateCell = useCallback((data: any, key: string) => {
    const value = formatDatetime(data[key], DATE_FORMATS.DATE)
    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipValue={value}
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
      >
        {value !== '--' && value}
      </TypographyLineClamp>
    )
  }, [])

  const renderOrgNameCell = useCallback((data: any, sx?: CSSProperties) => {
    const orgName = _get(data, 'name')
    const subOrgName = _get(data, 'subOrgName')

    return <TooltipSubOrgLabel mainLabel={subOrgName} subLabel={orgName} sx={sx} />
  }, [])

  const renderExpireDateCell = useCallback((data: any) => {
    const date = _get(data, 'expired_date', '')
    const daysToExpire = _get(data, 'days_to_expire', 0)
    if (!date) return ''
    const renderDate = formatDatetime(date, DATE_FORMATS.DATE)
    const renderExpireDate = ` - ${daysToExpire} days`

    if (renderDate.includes('2100'))
      return (
        <TypographyLineClamp
          line={1}
          variant="body2"
          tooltipValue={'Perpetual'}
          tooltipPlacement="top"
          tooltipProps={{
            tooltip: {
              sx: {
                color: '#1B273C',
                backgroundColor: '#E9EAEB',
                whiteSpace: 'pre-line'
              }
            },
            arrow: {
              sx: {
                color: '#E9EAEB'
              }
            }
          }}
        >
          Perpetual
        </TypographyLineClamp>
      )
    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipValue={`${renderDate} ${daysToExpire > 0 ? renderExpireDate : ''}`}
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
      >
        {renderDate}
        {daysToExpire > 0 ? ` - ${daysToExpire} days` : ''}
      </TypographyLineClamp>
    )
  }, [])

  const renderStatusCell = useCallback((data: any) => {
    const value = _get(data, 'status', '')
    const isEvaluation = _get(data, 'is_evaluation', false)
    const daysToExpire = _get(data, 'days_to_expire', 0)

    let color = '#008A00'
    if (value === 'expired') {
      color = '#D00300'
    }
    if (value === 'expiring') {
      if (isEvaluation) {
        color = '#D00300'
      } else if (daysToExpire < 90 && daysToExpire >= 30) {
        color = '#FDBD0D'
      } else {
        color = '#D00300'
      }
    }

    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipValue={value}
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
        textTransform="capitalize"
        style={{ color }}
      >
        {value}
      </TypographyLineClamp>
    )
  }, [])

  const renderTypeCell = useCallback((data: any, key: string) => {
    const value = data[key]
    if (value === undefined) {
      return
    }
    const type = value ? 'Evaluation' : 'Purchased'
    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipValue={value}
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
      >
        {type}
      </TypographyLineClamp>
    )
  }, [])

  const renderDeployedCell = useCallback((data: any, deployedKey: string, purchasedKey: string) => {
    const deployed = data[deployedKey]
    const purchased = data[purchasedKey]
    const value = deployed !== undefined && purchased !== undefined ? `${deployed}/${purchased}` : ''

    return (
      <TypographyLineClamp
        line={1}
        variant="body2"
        tooltipValue={value}
        tooltipPlacement="top"
        tooltipProps={{
          tooltip: {
            sx: {
              color: '#1B273C',
              backgroundColor: '#E9EAEB',
              whiteSpace: 'pre-line'
            }
          },
          arrow: {
            sx: {
              color: '#E9EAEB'
            }
          }
        }}
      >
        {value}
      </TypographyLineClamp>
    )
  }, [])

  const renderActionCell = useCallback((data: any) => {
    const licenses = _get(data, 'licenses') ?? []
    const children = _get(data, 'children') ?? []
    const status = _get(data, 'status', '')
    const isPinned = _get(data, 'is_pinned', false)
    const id = _get(data, 'id', '')

    const isChild = _get(data, 'is_child') ?? false // second level product
    const isParent = children.length > 0 || licenses.length > 0 // first level product
    const isExpired = status === 'expired'

    const parentItems = [
      {
        label: 'Download Product Installer',
        onClick: () => handleDownloadProduct(data)
      }
    ]

    const childItems = [
      {
        label: isPinned ? 'Unpin' : 'Pin',
        onClick: () => (isPinned ? handleUnpinLicense(id) : handlePinLicense(id))
      },
      {
        label: 'See full License History',
        onClick: () => handleViewHistory(data)
      },
      {
        label: 'Edit note',
        onClick: () => handleEditNote(data)
      },
      {
        label: 'Download Active Deployment Report',
        onClick: () => handleDownloadCSV(data)
      }
    ]

    if (isExpired) {
      childItems.pop()
    }
    return (
      !isChild && (
        <ActionButton
          items={isParent ? parentItems : childItems}
          paperStyles={{
            minWidth: '150px'
          }}
          buttonStyles={{
            padding: '0px'
          }}
        />
      )
    )
  }, [])

  const renderPinActionCell = useCallback((data: any) => {
    const licenses = _get(data, 'licenses') ?? []
    const children = _get(data, 'children') ?? []
    const status = _get(data, 'status', '')
    const id = _get(data, 'id', '')

    const isChild = _get(data, 'is_child') ?? false // second level product
    const isParent = children.length > 0 || licenses.length > 0 // first level product
    const isExpired = status === 'expired'

    const parentItems = [
      {
        label: 'Download Product Installer',
        onClick: () => handleDownloadProduct(data)
      }
    ]

    const childItems = [
      {
        label: 'Unpin',
        onClick: () => handleUnpinLicense(id)
      },
      {
        label: 'See full License History',
        onClick: () => handleViewHistory(data)
      },
      {
        label: 'Edit note',
        onClick: () => handleEditNote(data)
      },
      {
        label: 'Download Active Deployment Report',
        onClick: () => handleDownloadCSV(data)
      }
    ]

    if (isExpired) {
      childItems.pop()
    }
    return (
      !isChild && (
        <ActionButton
          items={isParent ? parentItems : childItems}
          paperStyles={{
            minWidth: '150px'
          }}
          buttonStyles={{
            padding: '0px'
          }}
        />
      )
    )
  }, [])

  const productColumns = useMemo(() => {
    return [
      {
        header: 'Product Name',
        style: { minWidth: '20vw', width: '20vw' },
        body: (data: any) =>
          renderOrgNameCell(data, {
            color: _get(data, 'slug') && !_get(data, 'is_child') ? '#1d6dfc' : undefined
          }),
        highlightLevels: [0],
        linkLevels: (data: any) => ['', `${homePageURL}/${_get(data, 'slug')}`]
      },
      {
        header: 'License Key',
        style: { minWidth: '18vw', width: '18vw' },
        body: (data: any) => renderCell(data, 'activation_key')
      },
      {
        header: 'Status',
        style: {
          minWidth: '5vw',
          width: '5vw',
          textAlign: 'center',
          sx: {
            justifyContent: 'center'
          }
        },
        body: renderStatusCell,
        hiddenLevels: [0]
      },
      {
        header: 'Start Date',
        style: { minWidth: '6vw', width: '6vw' },
        body: (data: any) => renderDateCell(data, 'activated_date'),
        hiddenLevels: [0]
      },
      {
        header: 'Expiration Date',
        style: { minWidth: '10vw', width: '10vw' },
        body: renderExpireDateCell,
        hiddenLevels: [0]
      },
      {
        header: 'Deployed/ Purchased',
        style: {
          minWidth: '5vw',
          width: '5vw',
          textAlign: 'center',
          sx: {
            justifyContent: 'center'
          }
        },
        body: (data: any) => renderDeployedCell(data, 'deployed_quantity', 'purchased_quantity'),
        hiddenLevels: [0]
      },
      {
        header: 'Type',
        style: { minWidth: '5vw', width: '5vw' },
        body: (data: any) => renderTypeCell(data, 'is_evaluation'),
        hiddenLevels: [0]
      },
      {
        header: 'Note',
        style: { minWidth: '9vw', width: '9vw' },
        body: (data: any) => renderCell(data, 'note'),
        hiddenLevels: [0]
      },
      {
        header: '',
        style: { minWidth: 40, textAlign: 'right' },
        body: renderActionCell,
        hiddenLevels: [0]
      }
    ]
  }, [])

  const childrenColumns = useMemo(() => {
    const columns = [...productColumns]
    columns.shift()
    return [
      {
        header: 'Product Name',
        style: { minWidth: '20vw', width: '20vw' },
        body: renderOrgNameCell,
        highlightLevels: [0]
      },
      ...columns
    ]
  }, [productColumns])

  const pinnedColumns = useMemo(() => {
    const columns = [...productColumns]
    columns.pop()
    return [
      ...columns,
      {
        header: '',
        style: { minWidth: 40, textAlign: 'right' },
        body: renderPinActionCell,
        hiddenLevels: [0]
      }
    ]
  }, [productColumns])

  const childrenPinnedColumns = useMemo(() => {
    const columns = [...pinnedColumns]
    columns.shift()
    return [
      {
        header: 'Product Name',
        style: { minWidth: '20vw', width: '20vw' },
        body: renderOrgNameCell,
        highlightLevels: [0]
      },
      ...columns
    ]
  }, [pinnedColumns])

  const TableMemo = useMemo(
    () => (
      <NestedSubOrgTable
        data={handleCheckEmptyData(mappedProductsData) ? [] : [mappedProductsData]}
        isLoading={isFetching}
        columns={productColumns}
        childrenColumns={childrenColumns}
        childrenKeys={[['products'], ['licenses', 'children'], ['licenses'], ['']]}
        levelPadding={24}
      />
    ),
    [mappedProductsData, productColumns, isFetching]
  )

  const PinnedTableMemo = useMemo(
    () => (
      <NestedSubOrgTable
        noHeader
        isLoading={isFetching}
        columns={pinnedColumns}
        childrenColumns={childrenPinnedColumns}
        data={handleCheckEmptyData(mappedPinnedData) ? [] : [mappedPinnedData]}
        childrenKeys={[['products'], ['licenses', 'children'], ['licenses'], ['']]}
        levelPadding={24}
        noDataText="There are no licenses to display."
      />
    ),
    [pinnedColumns, childrenPinnedColumns, mappedPinnedData, isFetching]
  )

  useEffect(() => {
    handleGetData({ ...searchFilters, organizationIds: organizationIds.map((item: any) => item.value) })
  }, [searchFilters, organizationIds])

  useEffect(() => {
    if (activateSuccessful) {
      handleGetData({ ...searchFilters, organizationIds: organizationIds.map((item: any) => item.value) })
    }
  }, [activateSuccessful])

  useEffect(() => {
    getProductOptions()
    handleGetPinnedData()
  }, [])

  return (
    <Grid item container xs={12}>
      <Grid item container xs={12} sx={{ padding: '15px', border: '1px solid #BCBFC3', gap: 4 }}>
        <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <TypographyDivider
            label={
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, height: '20px' }}>
                <PinIcon size={16} />
                <Typography variant="body1" marginTop="2px">
                  Pinned ({_get(mappedPinnedData, 'totalKeys', 0)})
                </Typography>
              </Box>
            }
            propsTypo={{
              component: 'label',
              variant: 'body1'
            }}
          />
          {PinnedTableMemo}
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2
          }}
        >
          <TypographyDivider
            label="Products"
            propsTypo={{
              component: 'label',
              variant: 'body1'
            }}
          />
          <LicensedProductsSectionFilter
            productOptions={productOptions}
            currentFilters={searchFilters}
            setCurrentFilters={setSearchFilters}
            handleSearchKeyword={(keyword: string) => {
              setSearchFilters((prev: any) => ({ ...prev, q: keyword.trim() }))
            }}
          />
          {TableMemo}
        </Grid>
      </Grid>
      {chosenItem && (
        <DialogDownloadVersion
          icapIntegrationsData={icapIntegrationsData}
          productDetailData={productDetailData}
          isOpenPVersion={openProductDialog}
          setIsOpenPVersion={setOpenProductDialog}
          handleDownloadedProductAdd={handleDownloadedProductAdd}
          isFetchingProductDetail={isFetchingProductDetail}
        />
      )}

      <DialogAdvanced
        open={openNoteDialog}
        onClose={() => setOpenNoteDialog(false)}
        iconClose={<CloseIcon />}
        title={'Note'}
        content={
          <BoxCommonDialogNote
            productId={_get(chosenItem, 'id')}
            licenseKey={_get(chosenItem, 'activation_key')}
            content={_get(chosenItem, 'note') ?? ''}
            isLoadingLicenseNoteAdd={licenseNoteAddResponse.isLoading}
            handleLicenseNoteAdd={handleLicenseNoteAdd}
            setOpenDialog={setOpenNoteDialog}
          />
        }
        dialogProps={{
          maxWidth: 'xs'
        }}
      />

      <DialogLicenseHistory
        orgId={_get(chosenItem, 'organization_id')}
        productId={_get(chosenItem, 'product_id')}
        isParent={_get(chosenItem, 'is_parent_org')}
        hiddenData={hiddenProductsData}
        openHistoryDialog={openHistoryDialog}
        onCloseHistoryDialog={() => {
          setOpenHistoryDialog(false)
          setChosenItem(undefined)
        }}
      />
    </Grid>
  )
}

export default ProductsSubOrgSection
