import { useCallback, useEffect, useState } from 'react'

import { TemplateSection } from '@opswat/react-ui'

import { useLazyPortalRolesQuery } from 'myopswat-web/src/api/role'

import { useModal } from '@ebay/nice-modal-react'
import {
  IPageInfoInput,
  IPortalRole,
  IPortalRolesFiltersInput,
  ISortInfoInput,
  Order
} from 'myopswat-web/src/api/role/types'
import { PAGE_DEFAULT, PAGE_SIZE_DEFAULT } from 'myopswat-web/src/constants'
import BoxRolesFilter from './BoxRolesFilter'
import BoxRolesTable from './BoxRolesTable'
import CreateUpdateRoleModal from './CreateUpdateRole/CreateUpdateRoleModal'
import DeleteRoleModal from './DeleteRole/DeleteRoleModal'

const defaultRolesFilter = { q: '', excludeSuperAdmin: false }
const defaultSortInfo = {
  order: 'desc' as Order,
  orderBy: 'updatedAt'
}
const defaultPageInfo = {
  page: PAGE_DEFAULT,
  pageSize: PAGE_SIZE_DEFAULT
}

interface IProps {
  permissions: string[]
}

const Roles = ({ permissions }: IProps) => {
  const [rolesFilter, setRolesFilter] = useState<IPortalRolesFiltersInput>(defaultRolesFilter)
  const [sortInfo, setSortInfo] = useState<ISortInfoInput>(defaultSortInfo)
  const [pageInfo, setPageInfo] = useState<IPageInfoInput>(defaultPageInfo)

  const [getPortalRoles, { data, isFetching }] = useLazyPortalRolesQuery()

  const handleGetPortalRoles = (
    rolesFilter?: IPortalRolesFiltersInput,
    sortInfo?: ISortInfoInput,
    pageInfo?: IPageInfoInput
  ) => {
    if (!rolesFilter) rolesFilter = defaultRolesFilter
    if (!sortInfo) sortInfo = defaultSortInfo
    if (!pageInfo) pageInfo = defaultPageInfo
    setRolesFilter(rolesFilter)
    setSortInfo(sortInfo)
    setPageInfo(pageInfo)
    getPortalRoles({ filters: rolesFilter, sortInfo: sortInfo, pageInfo: pageInfo })
  }

  useEffect(() => {
    handleGetPortalRoles(defaultRolesFilter, sortInfo)
  }, [])

  useEffect(() => {
    handleGetPortalRoles(rolesFilter, sortInfo, pageInfo)
  }, [JSON.stringify(rolesFilter), JSON.stringify(sortInfo), JSON.stringify(pageInfo)])

  // Modals
  const createUpdateRoleModal = useModal(CreateUpdateRoleModal)
  const deleteRoleModal = useModal(DeleteRoleModal)

  const handleClickNew = useCallback(() => {
    createUpdateRoleModal.show().then(() => {
      handleGetPortalRoles()
    })
  }, [])

  const handleClickEdit = useCallback((selectedPortalRole: IPortalRole) => {
    createUpdateRoleModal.show({ selectedPortalRole }).then(() => {
      handleGetPortalRoles()
    })
  }, [])

  const handleClickDetail = useCallback((selectedPortalRole: IPortalRole) => {
    createUpdateRoleModal.show({ selectedPortalRole, viewOnly: true }).then(() => {
      handleGetPortalRoles()
    })
  }, [])

  const handleClickDelete = useCallback(
    (selectedPortalRole: IPortalRole) => {
      if (data?.organizationPortalRoles) {
        deleteRoleModal.show({ selectedPortalRole }).then(() => {
          handleGetPortalRoles()
        })
      }
    },
    [data]
  )

  return (
    <TemplateSection spacing={2}>
      <BoxRolesFilter setRolesFilter={setRolesFilter} onClickNew={handleClickNew} permissions={permissions}/>
      <BoxRolesTable
        portalRolesData={data}
        isFetchingRoles={isFetching}
        setSortInfo={setSortInfo}
        sortInfo={sortInfo}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
        onClickDetail={handleClickDetail}
        setPageInfo={setPageInfo}
        pageInfo={pageInfo}
        permissions={permissions}
      />
    </TemplateSection>
  )
}

export default Roles
