import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormDialog } from '@opswat/react-ui'
import _min from 'lodash/min'
import {
  useLazyRoleObjectsQuery,
  usePortalRoleCreateMutation,
  usePortalRoleUpdateMutation
} from 'myopswat-web/src/api/role'
import { ICreateUpdatePortalRoleForm, IPortalRole, IRoleObjectForm } from 'myopswat-web/src/api/role/types'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import CreateUpdateRoleForm from './CreateUpdateRoleForm'
import { roleSchema } from './RoleSchema'

interface IProps {
  selectedPortalRole?: IPortalRole
  viewOnly?: boolean
}

const defaultPortalRole = {
  name: '',
  description: '',
  roleObjects: []
}

const CreateUpdateRoleModal = NiceModal.create(({ selectedPortalRole, viewOnly }: IProps) => {
  const { t: translate } = useTranslation()
  const [portalRoleCreate, { isLoading: isCreating }] = usePortalRoleCreateMutation()
  const [portalRoleUpdate, { isLoading: isUpdating }] = usePortalRoleUpdateMutation()
  const { enqueueSnackbar } = useSnackbar()
  const modal = useModal()
  const formRef = useForm<ICreateUpdatePortalRoleForm>({
    defaultValues: defaultPortalRole,
    mode: 'onSubmit',
    resolver: yupResolver(roleSchema)
  })
  const { reset, handleSubmit, formState: {isDirty}, watch, setValue } = formRef
  const roleObjectsInput = watch('roleObjects')
  const [getRoleObjects, { data: defaultRoleObjects, isFetching: isFetchingRoleObjects }] = useLazyRoleObjectsQuery()

  const onSubmit: SubmitHandler<ICreateUpdatePortalRoleForm> = async (formData: ICreateUpdatePortalRoleForm) => {
    try {
      let res
      if (selectedPortalRole) res = await portalRoleUpdate(formData).unwrap()
      else res = await portalRoleCreate(formData).unwrap()
      if (res.success) {
        if (selectedPortalRole) enqueueSnackbar(translate('updatePortalRoleSuccess'), { variant: 'success' })
        else enqueueSnackbar(translate('createPortalRoleSuccess'), { variant: 'success' })
        handleResetForm()
        modal.resolve()
        modal.hide()
      } else if (selectedPortalRole) {
        enqueueSnackbar(translate('updatePortalRoleFail'), { variant: 'error' })
      } else {
        enqueueSnackbar(translate('createPortalRoleFail'), { variant: 'error' })
      }
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }
  const onError = (error: any) => {
    console.error('error: ', error)
  }

  const handleResetForm = () => {
    reset(defaultPortalRole)
  }

  const onCancel = () => {
    handleResetForm()
    modal.hide()
  }

  useEffect(() => {
    if (modal.visible) getRoleObjects({})
  }, [modal.visible])

  const isLoading = useMemo(() => {
    return isCreating || isUpdating
  }, [isCreating || isUpdating])

  const handleSelectPermission = (roleObject: IRoleObjectForm) => {
    const excludeOldRoleObjects = roleObjectsInput.filter(r => r.id.toString() !== roleObject.id.toString())
    return [...excludeOldRoleObjects, roleObject]
  }

  useEffect(() => {
    if (isFetchingRoleObjects || !defaultRoleObjects) return

    let defaultRoleObjectInput: IRoleObjectForm[] = []
    if (selectedPortalRole) defaultRoleObjectInput = [...selectedPortalRole.roleObjects]

    defaultRoleObjects.forEach(defaultObject => {
      const existedRoleObject = defaultRoleObjectInput.find(inputObject => inputObject.id === defaultObject.id)
      if (!existedRoleObject) {
        defaultRoleObjectInput.push({
          id: defaultObject.id,
          selectedPermissions: [_min(defaultObject.permissions.map(r => r.priority)) ?? 1]
        })
      }
    })

    reset({ id: selectedPortalRole?.id, name: selectedPortalRole?.name, description: selectedPortalRole?.description, roleObjects: defaultRoleObjectInput })
  }, [selectedPortalRole, defaultRoleObjects, isFetchingRoleObjects])

  return (
    <FormDialog
      isLoading={isLoading}
      isDisableSubmit={!isDirty || selectedPortalRole?.isDefault || viewOnly}
      title={selectedPortalRole ? 'Role detail' : 'Create Role'}
      open={modal.visible}
      onClose={() => modal.hide()}
      onExited={() => modal.remove()}
      onCancel={onCancel}
      onConfirm={handleSubmit(onSubmit, onError)}
      confirmBtnLabel="Submit"
      minHeight={300}
      maxWidth="sm"
      hideConfirmBtn={viewOnly}
      content={
        <CreateUpdateRoleForm
          formRef={formRef}
          defaultRoleObjects={defaultRoleObjects}
          isFetchingRoleObjects={isFetchingRoleObjects}
          onSelectPermission={handleSelectPermission}
          viewOnly={viewOnly || selectedPortalRole?.isDefault}
        />
      }
    />
  )
})

export default CreateUpdateRoleModal
