import _get from 'lodash/get'

import { useMemo } from 'react'
import { useForm } from 'react-hook-form'

import SubOrgLicenseView from '.'
import { SubOrgViewContext } from './interface'

interface IProps {
  permission: any
  organizationTreeData: any
  setCsvFilter: (data: any) => void
  activateSuccessful: boolean
}

const SubOrgViewProvider: React.FC<IProps> = ({
  permission,
  organizationTreeData,
  setCsvFilter,
  activateSuccessful
}) => {
  const { control } = useForm<any>({
    defaultValues: {
      organizationIds: []
    }
  })

  const organizationList = useMemo(() => {
    const parent = _get(organizationTreeData, 'tree', {})
    const children = _get(parent, 'children', [])
      .map((item: any) => ({ ...item, level: 1 }))
      .sort((a: any, b: any) => a.name.localeCompare(b.name))
    return [{ ...parent, level: 0 }, ...children]
  }, [organizationTreeData])

  const contextValue = useMemo(
    () => ({
      control,
      organizationList,
      activateSuccessful,
      setCsvFilter
    }),
    [control, organizationList, activateSuccessful, setCsvFilter]
  )

  return (
    <SubOrgViewContext.Provider value={contextValue}>
      <SubOrgLicenseView />
    </SubOrgViewContext.Provider>
  )
}
export default SubOrgViewProvider
