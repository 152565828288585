import { FC, useCallback, useMemo } from 'react'

import {
  Box,
  Dropdown,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  TableAdvanced,
  Tooltip,
  Typography,
  TypographyLineClamp
} from '@opswat/react-ui'
import _get from 'lodash/get'

import { formatDatetime } from '@opswat/react-core'
import { OptionIcon } from '@opswat/react-icon'
import { IPageInfoInput, IPortalRole, ISortInfoInput, Order } from 'myopswat-web/src/api/role/types'
import { PAGE_DEFAULT } from 'myopswat-web/src/constants'
import { myOrganizationUsersPageURL } from 'myopswat-web/src/routes'
import { useNavigate } from 'react-router-dom'
import useHasPermissions from '../../../hooks/useHasPermissions'

interface IProps {
  isFetchingRoles: boolean
  portalRolesData: any
  sortInfo: ISortInfoInput
  setSortInfo: (val: any) => void
  onClickEdit: (data: any) => void
  onClickDelete: (data: any) => void
  onClickDetail: (data: any) => void
  setPageInfo: (data: any) => void
  pageInfo: IPageInfoInput
  permissions: string[]
}

const BoxRolesTable: FC<IProps> = ({
  portalRolesData,
  isFetchingRoles,
  sortInfo,
  pageInfo,
  setSortInfo,
  onClickEdit,
  onClickDelete,
  onClickDetail,
  setPageInfo,
  permissions
}) => {
  const hasViewOrgRolePerm = useHasPermissions({targetPerms: ['view_my_organizations_role', 'full_my_organizations_role'], userPerms: permissions})
  const hasFullOrgRolePerm = useHasPermissions({targetPerms: ['full_my_organizations_role'], userPerms: permissions})
  const navigate = useNavigate()

  const handleClickNumberOfUser = useCallback((id: string) => {
    navigate(myOrganizationUsersPageURL, { state: { portalRoles: [id] } })
  }, [])

  const handleOrderChange = (orderBy: string) => {
    const isAsc = sortInfo.orderBy === orderBy && sortInfo.order === 'asc'
    const newSortInfo = { order: isAsc ? 'desc' : ('asc' as Order), orderBy }
    setSortInfo(newSortInfo)
  }

  const handlePaginationOnChange = (page: number, pageSize: number) => {
    if (pageSize !== pageInfo.pageSize) page = PAGE_DEFAULT
    setPageInfo({ page: page, pageSize: pageSize })
  }

  const columnArray = useMemo(() => {
    return [
      {
        key: 'name',
        isSortable: portalRolesData?.sortableCols.includes('name'),
        header: 'Name',
        body: (data: IPortalRole) => (
          <TypographyLineClamp line={2} variant="body2">
            {_get(data, 'name', '--')}
          </TypographyLineClamp>
        ),
        style: { minWidth: 200, width: 200 }
      },
      {
        key: 'description',
        isSortable: portalRolesData?.sortableCols.includes('description'),
        header: 'Description',
        body: (data: IPortalRole) => (
          <Tooltip title={_get(data, 'description', '--')} arrow placement="top">
            <Box>
              <TypographyLineClamp line={2} variant="body2">
                {_get(data, 'description', '--')}
              </TypographyLineClamp>
            </Box>
          </Tooltip>
        ),
        style: { minWidth: 500, width: 500 }
      },
      {
        key: 'numberOfUsers',
        isSortable: portalRolesData?.sortableCols.includes('numberOfUsers'),
        header: 'Number Of Users',
        body: (data: IPortalRole) =>
          _get(data, 'numberOfUsers', 0) > 0 ? (
            <Typography
              variant="body2"
              color="primary"
              sx={{
                marginRight: 2,
                '&:hover': {
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }
              }}
              onClick={() => handleClickNumberOfUser(_get(data, 'id', ''))}
            >
              {_get(data, 'numberOfUsers')}
            </Typography>
          ) : (
            <Typography variant="body2">--&nbsp;&nbsp;&nbsp;&nbsp;</Typography>
          ),
        style: { minWidth: 180, width: 180, textAlign: 'center' }
      },
      {
        key: 'lastUpdatedBy',
        isSortable: portalRolesData?.sortableCols.includes('lastUpdatedBy'),
        header: 'Last Updated By',
        body: (data: IPortalRole) => _get(data, 'lastUpdatedBy.email', '--'),
        style: { minWidth: 300, width: 300, textAlign: 'center' }
      },
      {
        key: 'updatedAt',
        header: 'Last Updated',
        isSortable: portalRolesData?.sortableCols.includes('updatedAt'),
        body: (data: IPortalRole) => formatDatetime(_get(data, 'updatedAt', ''), 'yyyy-MM-dd, HH:MM:ss'),
        style: { minWidth: 200, width: 200, textAlign: 'center' }
      },
      {
        header: '',
        body: (data: IPortalRole) => (
          <Dropdown
            button={
              <IconButton>
                <OptionIcon />
              </IconButton>
            }
            content={
              <List>
                <ListItem disablePadding>
                  <ListItemButton onClick={() => onClickDetail(data)} disabled={!hasViewOrgRolePerm}>Detail</ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton disabled={data.isDefault || !hasFullOrgRolePerm} onClick={() => onClickEdit(data)}>
                    Edit
                  </ListItemButton>
                </ListItem>
                <ListItem disablePadding>
                  <ListItemButton disabled={data.isDefault || !hasFullOrgRolePerm} onClick={() => onClickDelete(data)}>
                    Delete
                  </ListItemButton>
                </ListItem>
              </List>
            }
          />
        ),
        style: { minWidth: 40, textAlign: 'right' }
      }
    ]
  }, [portalRolesData?.sortableCols])

  return (
    <TableAdvanced
      boxMinHeight={'0px'}
      columns={columnArray}
      isLoading={isFetchingRoles}
      data={portalRolesData?.organizationPortalRoles}
      order={sortInfo.order}
      orderBy={sortInfo.orderBy}
      onOrderChange={handleOrderChange}
      isPagination
      total={portalRolesData?.totalCount}
      page={pageInfo.page}
      pageSize={pageInfo.pageSize}
      onPageChange={handlePaginationOnChange}
    />
  )
}

export default BoxRolesTable
